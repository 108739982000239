import { EyeTwoTone, IdcardOutlined, KeyOutlined, SignatureOutlined, EyeInvisibleOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Card, Spin, Button, Row, Col, Input, Form, Modal, Image } from 'antd';
import Defaultimage from "../../assets/images/dummyimage.png";
import AxiosInstance from "../../services/axiosInstance";
import dayjs from 'dayjs';
import { Toaster } from "../../utility/Toaster";
import { message as messageApi } from 'antd';
import SignatureRecorder from '../../utility/SignatureRecorder';
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
const Profile = () => {
  const { t } = useTranslation();
  const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
  const token = localStorage.getItem("rType");
  const [rType, setRType] = useState(token || '');
  const sigCanvas = useRef(null);
  // const pwdPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i;
  const { Meta } = Card;
  const uType = localStorage.getItem("type");
  const [loading, setLoading] = useState(false);
  const [profileCardVisible, setProfileCardVisible] = useState(false);
  const [passwordCardVisible, setPasswordCardVisible] = useState(false);
  const [signatureCardVisible, setSignatureCardVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [contactCli, setContactCli] = useState('');
  const [contactEmp, setContactEmp] = useState('');
  const [pin, setPin] = useState('');
  const [changePasswordForm] = Form.useForm();
  const [showDigitalSign, setShowDigitalSign] = useState(false);
  const [base64Audio, setBase64Audio] = useState(null);
  const [signDetails, setSignDetail] = useState("");
  const [ShowSignature, setShowSignature] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const clearCanvas = () => {
    sigCanvas.current.clear();
    setIsSignatureEmpty(true);
  };

  const handleDraw = () => {
    setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
  };
  // const handleDone = () => {
  //     // const note = inputRef.current?.resizableTextArea?.textArea?.value.trim();
  //     console.log(sigCanvas.current.toDataURL("image/png"), "signxxxxx");
  //     // onDone(sigCanvas.current.toDataURL("image/png"), note);
  //     setIsSignatureEmpty(false);
  // };

  const hideImage = () => {
    setShowSignature(false)
  };
  const handleDone = () => {
    const obj = {
      companyId: userDetails.companyId._id,
      empId: userDetails._id,
      signature: sigCanvas.current.toDataURL("image/png"),
      pin: pin,
      type: 'Digital Sign',
      rp: rType !== "rp" ? false : true
    };
    setLoading(true);
    const url = "emp/digital-sign";
    AxiosInstance.post(url, obj)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          // setSignData("");
          clearCanvas();
          getDigitalSign();
          setIsSignatureEmpty(false);
          setPasswordCardVisible(false);
          Toaster("success", "", res.data.message);
        } else {
          Toaster("Error", "Error", res.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const getDigitalSign = () => {
    setLoading(true);
    const endpoint = `emp/digital-sign/${userDetails._id}`;
    AxiosInstance.get(endpoint)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          setShowSignature(true)
          setSignDetail(res.data.data);
          if (res.data.data) setPin(res.data.data.pin)
        } else {
          setSignDetail("");
          setShowSignature(false)
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (rType !== "rp") getUserProfile();
  }, []);


  const handleChangePin = (value, type) => {
    setPin(value);
  };

  useEffect(() => {
    const audioElement = document.getElementById("audioElement");

    if (audioElement && base64Audio?.signature) {
      audioElement.src = `data:audio/webm;base64,${base64Audio.signature}`;
      audioElement.load();
    }
    return () => {
      // Cleanup code if needed
    };
  }, [base64Audio?.signature]);

  const getUserProfile = () => {
    setLoading(true);
    let endpoint = { e: 'emp/get-profile', c: 'client/profile' };
    let local = endpoint[uType];
    AxiosInstance.get(local, '')
      .then(res => {
        setLoading(false);
        if (res.data.success) {
          setUserInfo(res.data.data);
          uType === 'e' ? setContactEmp(res.data.data.phone) : setContactCli(res.data.data.phone1)
        } else {
          setUserInfo("");
          setContactEmp('');
          setContactCli('');
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }
  const handleChange = (value, type) => {
    setContactEmp('');
    setContactCli('');
    if (type === 'phone1') {
      setContactCli(value);
    } else if (type === 'phone') {
      setContactEmp(value);
    }
  };
  const ShowProfileCard = () => {
    setProfileCardVisible(!profileCardVisible); // Toggle profile card visibility
    setPasswordCardVisible(false);
    setSignatureCardVisible(false);
  }
  const ShowSignCard = () => {
    getDigitalSign();
    setSignatureCardVisible(!signatureCardVisible); // Toggle profile card visibility
    setPasswordCardVisible(false);
    setProfileCardVisible(false)
  }
  const ShowPasswordCard = () => {
    setPasswordCardVisible(!passwordCardVisible); // Toggle profile card visibility
    setProfileCardVisible(false);
    setSignatureCardVisible(false);
  }

  // const digitalRecording = () => {
  //   setShowDigitalSign(true)
  //   getClientRecording()
  // }

  const onSelectVoice = (signature, pin, audioMimeType) => {
    if (signature && pin) {
      addRecording(signature, pin, audioMimeType)
    }
  };
  const addRecording = (signature, pin, audioMimeType) => {
    const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    setLoading(true);
    let obj = {
      "companyId": userInfo.companyId._id,
      "clientId": userInfo._id,
      "pin": pin,
      "signature": signature,
      "type": audioMimeType
    };

    let endpoint = "client-signature";
    AxiosInstance.post(endpoint, obj)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          // Update state with new base64Audio data
          setBase64Audio(res.data.data);
          // Show toast message
          // console.log(res.data.message);
          messageApi.success(res.data.message);
        } else {
          // Handle failure scenario
          console.error(res.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error adding recording:", error);
      });
  };

  const isRecorded = (isStarted) => {
    // setDisabled(isStarted);
  };

  const getClientRecording = () => {
    const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    let endpoint = "client-signature/" + userInfo._id;
    setLoading(true);
    AxiosInstance.get(endpoint)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          setBase64Audio(data.data);
        } else {
          setBase64Audio({});
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });

  }

  const handleOk = () => {
    setLoading(true);
    let obj = {};
    if (uType === 'c') obj.phone1 = contactCli;
    else obj.phone = contactEmp;
    let endpoint = { e: 'emp/update-info', c: 'client/update-info' };
    AxiosInstance.put(endpoint[uType], obj)
      .then(({ data }) => {
        if (data.success) {
          messageApi.success(data.message);
          setProfileCardVisible(false)
          getUserProfile()
        } else {
          Toaster("info", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);

      })
      .finally(() => {
        // Reset loading to false when the operation is complete
        setLoading(false);
      });
  }
  // const [image, setImage] = useState(null);

  const closeSignatureModal = () => {
    setShowDigitalSign(false);
  }

  const deleteRecording = (id) => {
    let endpoint = "client-signature/" + id;
    AxiosInstance.delete(endpoint, {
      data: {}
    })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          getClientRecording();
          // Show toast message
          // console.log(res.data.message);
          messageApi.success(res.data.message);
        } else {
          // Handle failure scenario
          messageApi.error(res.data.message);
          // console.error(res.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.error("Error deleting recording:", error);
        Toaster("success", "Success", error);
      });
  };

  const submitChangePasswordForm = () => {
    const payload = {
      oldPassword: changePasswordForm.getFieldValue('oldPassword'),
      newPassword: changePasswordForm.getFieldValue('newPassword'),
    };
    const endpoint = { e: 'emp/update-password', c: 'client/update-password' };
    const local = endpoint[uType];
    AxiosInstance.put(local, payload)
      .then(({ data }) => {
        if (data.success) {
          setPasswordCardVisible(false)
          Toaster("success", "Success", data.message);
        } else {
          Toaster("success", "Success", data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <Spin
        spinning={loading}
        fullscreen
        size="large"
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            width: 800,
            marginTop: 16,
          }}
          actions={
            rType !== "rp"
              ? [
                  <span style={{ fontWeight: "bold" }} onClick={ShowProfileCard}>
                    <IdcardOutlined style={{ fontSize: "1rem" }} /> &nbsp;
                    {t("EditProfile")}
                  </span>,
                  <span style={{ fontWeight: "bold" }} onClick={ShowSignCard}>
                    <SignatureOutlined style={{ fontSize: "1rem" }} /> &nbsp;
                    {t("Digital Signature")}
                  </span>,
                  <span style={{ fontWeight: "bold" }} onClick={ShowPasswordCard}>
                    <KeyOutlined style={{ fontSize: "1rem" }} /> &nbsp;
                    {t("Privacy")} {t("Change Password")}
                  </span>,
                ]
              : [
                <span style={{ fontWeight: "bold" }} onClick={ShowSignCard}>
                <SignatureOutlined style={{ fontSize: "1rem" }} /> &nbsp;
                {t("Digital Signature")}
              </span>
              ]
          }
        >
          <Meta
            avatar={
              <div style={{ position: "relative" }}>
                <Avatar
                  style={{
                    marginTop: "10px",
                    width: "120px",
                    height: "120px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  src={userInfo?.profile ? userInfo?.profile : Defaultimage}
                />
                {/* <CameraOutlined
                    style={{
                      position: "absolute",
                      bottom: 18,
                      right: 10,
                      fontSize: "16px",
                      color: "white",
                      cursor: "pointer",
                      backgroundColor: "cornflowerblue",
                      padding: "5px",
                      borderRadius: "50%",
                    }}
                  /> */}
              </div>
            }
            title={
              <div style={{ marginTop: "30px" }}>

                {rType !== "rp" ? (
                  <span>{userInfo?.fName} {userInfo?.lName}</span>
                ) : (
                  <span>{userDetails?.rName}</span>
                )}


                {/* {uType === "c" && (
                  <span
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={digitalRecording}
                  >
                    <Tag color="blue"><FormattedMessage id="Sign Recording" /></Tag>
                  </span>
                )} */}
              </div>
            }
            description={
              <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                {uType === "e" && userInfo?.umpi ? (
                  <span>UMPI: {userInfo?.umpi}</span>
                ) : (
                  <>
                    {rType !== "rp" ? (
                      <span>PMI: {userInfo?.pmi}</span>
                    ) : null}
                  </>
                )}



                {rType !== "rp" ? (
                  <div>
                    {" "}
                    DOB: {dayjs(userInfo?.dob).add(1, 'day').format("MM/DD/YYYY")}{" "}
                  </div>
                ) : (
                  <div>Relation: {userDetails?.rRelation}</div>
                )}


                <div>Email: {rType !== "rp" ? userInfo?.email : userDetails?.rEmail}</div>
              </div>
            }
          />
          {/* <MapWithMarkers markers={markers} /> */}
        </Card>
      </div>

      {signatureCardVisible && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            style={{
              width: 800,
              marginTop: -1,
            }}
          >
            <Meta
              description={
                <div
                  style={{
                    marginBottom: "10px",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                >
                  <Row
                    gutter={[24, 24]}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      justifyContent: 'center', // Center the row horizontally
                      alignItems: 'center', // Center the row vertically
                      fontWeight: "bold",
                    }}
                  >
                    <Col span={18} xs={18} sm={18} md={18} lg={18} xl={18}>
                      <div>
                        <label>{t('PIN')}</label>
                      </div>
                      <Input
                        placeholder={t('Enter 4 digit PIN')}
                        value={pin}
                        maxLength={4}
                        // onChange={handleChangePin}
                        onChange={(e) =>
                          handleChangePin(e.target.value, "pin")
                        }
                      />
                    </Col>
                  </Row>
                  {!ShowSignature ? (
                    <Row
                      gutter={[16, 0]}
                      style={{
                        justifyContent: 'center', // Center the row horizontally
                        alignItems: 'center', // Center the row vertically
                        padding: '10px',
                        // width:'60%'
                      }}
                    >
                      <SignatureCanvas
                        canvasProps={{
                          width: 400,
                          height: 200,
                          style: { border: '2px solid #000' }, // Add border style
                        }}
                        ref={sigCanvas}
                        onBegin={handleDraw}
                      />
                    </Row>


                  ) :


                    <Row
                      justify="center"
                      align="middle"
                      style={{
                        position: 'relative', // Needed for positioning the close icon
                        border: '1px solid #000', // Border for the box
                        width: '300px',
                        height: '300px',
                        margin: '0 auto', // Center the box horizontally
                        padding: '10px',
                      }}
                    >
                      <Col>
                        {/* Image inside the box */}
                        <Image
                          src={signDetails?.signature}
                          alt="Signature"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                        {/* Cross icon in the corner */}
                        <Button
                          type="primary"
                          // shape="circle"
                          icon={<CloseOutlined />}
                          onClick={hideImage}
                          style={{
                            position: 'absolute',
                            top: '-70px',
                            right: '-5px',
                          }}
                        />
                      </Col>
                    </Row>
                  }

                  <Row
                    gutter={[16, 0]}
                    style={{
                      justifyContent: 'flex-end', // Align the buttons to the right
                      marginTop: '10px',
                      padding: 10
                    }}
                  >
                    <Button
                      type="primary"
                      disabled={ShowSignature || isSignatureEmpty}
                      danger
                      style={{ marginRight: "4px" }}
                      onClick={clearCanvas}
                    >
                      Clear
                    </Button>
                    <Button
                      disabled={ShowSignature || isSignatureEmpty}
                      type="primary"
                      onClick={handleDone}
                    >
                      Done
                    </Button>
                  </Row>
                </div>
              }
            />
          </Card>
        </div>
      )}

      {profileCardVisible && rType !== "rp" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            style={{
              width: 800,
              marginTop: -1,
            }}
            actions={[
              <Button
                className="ant-btn-form"
                type="primary"
                onClick={handleOk}
                loading={loading}
              >
                {t('Update')}
              </Button>,
            ]}
          >
            <Meta
              description={
                <div
                  style={{
                    marginBottom: "10px",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                >
                  <Row
                    gutter={[16, 0]}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                      <div>
                        <label>{t('Name')}</label>
                      </div>
                      <Input
                        placeholder={t('EnterName')}
                        disabled
                        value={`${userInfo?.fName} ${userInfo?.lName} `}
                      />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                      <div>
                        <label>{t('Email')}</label>
                      </div>
                      <Input
                        placeholder={t('Enter Email')}
                        // placeholder={<FormattedMessage id="EnterEmail" />}
                        disabled
                        value={userInfo?.email}
                      />
                    </Col>
                    {uType === "e" ? (
                      <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
                        <div>
                          <label>{t('Contact')}</label>
                        </div>
                        <Input
                          placeholder={t('Enter Phone Number')}
                          value={contactEmp}
                          maxLength={10}
                          onChange={(e) =>
                            handleChange(e.target.value, "phone")
                          }
                        />
                        {/* value={contactEmp.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1)$2-$3"
                            )} */}
                      </Col>
                    ) : (
                      <Col span={6} xs={24} sm={24} md={12} lg={6} xl={6}>
                        <div>
                          <label>{t('Contact')}</label>
                        </div>
                        <Input
                          placeholder={t('Enter Phone Number')}
                          value={contactCli}
                          maxLength={10}
                          onChange={(e) =>
                            handleChange(e.target.value, "phone1")
                          }
                        />
                      </Col>
                    )}
                    <Col span={6} xs={24} sm={24} md={12} lg={6} xl={24}>
                      <div>
                        <label>{t('Address')}</label>
                      </div>
                      <Input
                        placeholder={t('Address')}
                        disabled
                        value={`${userInfo?.street} ${userInfo?.zip} ${userInfo?.city} ${userInfo?.state} ${userInfo?.county}`}
                      />
                    </Col>
                  </Row>
                </div>
              }
            />
          </Card>
        </div>
      )}

      {passwordCardVisible && rType !== "rp" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card
            style={{
              width: 800,
              marginTop: -1,
            }}
            actions={[
              // <Button className="ant-btn-form"
              //   type="primary"
              //   onClick={submitChangePasswordForm}
              //   loading={warningLoading}
              // >
              //   <FormattedMessage id="UpdatePassword" />
              // </Button>,
            ]}
          >
            <Meta
              description={
                <>
                  <div style={{ fontWeight: 'bold', marginTop: 10, textAlign: "center" }}>
                    {t('Create your new password so you can login to your account')}
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                      marginTop: "20px",
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    <Form
                      className='user-info'
                      layout="vertical"
                      form={changePasswordForm}
                      name="register"
                      onFinish={submitChangePasswordForm}
                      size='small'
                      style={{
                        marginLeft: "10px",
                      }}
                      scrollToFirstError
                    >
                      <Form.Item
                        name="oldPassword"
                        label={t('Current Password')}
                        rules={[
                          {
                            required: true,
                            message: 'Current password is required',
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="newPassword"
                        label={t('New Password')}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter your new password!",
                          },
                          // ({ getFieldValue }) => ({
                          //   validator(_, value) {
                          //     if (!value || pwdPattern.test(value)) {
                          //       return Promise.resolve();
                          //     }
                          //     return Promise.reject(<FormattedMessage id="Passwordmustcontainatleastonelowercaseletteroneuppercaseletteronedigitandbeatleast8characterslong" />
                          //     );
                          //   },
                          // }),
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirm"
                        label={t('Confirm Password')}
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your new password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                            // validator(_, value) {
                            //   if (
                            //     !value ||
                            //     getFieldValue("newPassword") === value
                            //   ) {
                            //     return Promise.resolve();
                            //   }
                            //   return Promise.reject(
                            //     new Error(<FormattedMessage id="Thenewpasswordthatyouentereddonotmatch" />
                            //    )
                            //   );
                            // },
                          }),
                        ]}
                      >
                        <Input.Password
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                      <Form.Item label=" " style={{ textAlign: 'center' }}>
                        <Button className="ant-btn-form" type="primary" htmlType="submit">{t('UpdatePassword')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </>
              }
            />
          </Card>
        </div>
      )}
      <Modal
        label={t('Digital Signature')}
        open={showDigitalSign}
        onCancel={closeSignatureModal}
        closable={true}
        footer={null}
        loading={loading}
        maskClosable={false}
      >
        {/* {getDataForClientPin && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
                Please enter client signature pin first
              </Title>
            </div>
            <div>
              <Input placeholder="Enter PIN" onChange={handlePinChange} value={getPinValue} />
            </div>
          </div>
          <div>

        )} */}
        <div>
          {/* <Button type="primary" block>
            Primary
          </Button> */}
          <SignatureRecorder onVoice={onSelectVoice}
            isRecordStared={isRecorded} type="pin" />
        </div>

        <div>
          {/* <h3>Audio Sign</h3> */}
          {base64Audio?.clientId && (
            <h3 className="">
              {base64Audio?.clientId?.fName} {base64Audio?.clientId?.lName} {base64Audio?.clientId?.mName}
              <span style={{ marginLeft: "30px" }}>{t('PIN')}: {base64Audio?.pin}</span>
            </h3>
          )}
          <div style={{ display: 'flex', gap: '9px' }}>
            {base64Audio?.signature && (
              <audio controls id="audioElement" style={{ width: "90%", height: "40px" }}>
                <source src={`data:audio/webm;base64,${base64Audio.signature}`} type="audio/webm" />
                <source src={`data:audio/wav;base64,${base64Audio.signature}`} type="audio/wav" />
                <source src={`data:audio/ogg;base64,${base64Audio.signature}`} type="audio/ogg" />
                <source src={`data:audio/mpeg;base64,${base64Audio.signature}`} type="audio/mpeg" />
                {t('You rbrowser does not support the audio element')}
              </audio>
            )}
            {base64Audio?._id && (
              <DeleteOutlined onClick={() => deleteRecording(base64Audio._id)} style={{ fontSize: '35px', color: '#ff4d4f' }} />
              // <button >Delete</button>
            )}</div>
        </div>
      </Modal>
    </>
  );
};
export default Profile;