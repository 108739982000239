import React, { useRef, useState, useEffect } from "react";
import { Modal, Button, Typography, Input,Row,Col} from "antd";
import SignatureCanvas from "react-signature-canvas";
import SignatureRecorder from "../../utility/SignatureRecorder";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Message } from "../../utility/MessageUtil";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { TextArea } = Input;

const disabledDiv = {
  opacity: "0.5",
  PointerEvent: "none",
};

const SignatureModal = ({
  showSignature,
  handleCancel,
  type,
  loading,
  onDone,
  isAddNotes = false,
}) => {
  const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
  const uType = localStorage.getItem("type");
  const sigCanvas = useRef(null);
  const inputRef = useRef(null);
  const [disable, setDisabled] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [isDigitalOpen, setDigitalModalOpen] = useState(false);
  const [pin, setPin] = useState('');
  const { t } = useTranslation();
  const clearCanvas = () => {
    sigCanvas.current.clear();
    setIsSignatureEmpty(true);
  };

  const handleChangePin = (value, type) => {
      setPin(value);
    
  };

  useEffect(() => {
    if (pin.length === 4) {
      getDigitalSign(pin);
    }
  }, [pin]);

  const showDigitalModal = () => {
    // handleCancel()
    setDigitalModalOpen(true);
  };
  const getDigitalSign = (code) => {
    // setLoading(true);
    const local = `emp/digital-sign/${userDetails._id}`;
    const params = { 
      biometric: false,
      pin: code
     };
    AxiosInstance.get(local, { params })
      .then((res) => {
        // setLoading(false);
        if (res.data.success) {
          onDone(res.data.data.signature);
          // handleData({ sign: res.data.data.signature, type: data.type,});
          // Toaster("success", "Success", res.data.message);
          setDigitalModalOpen(false)
          // setClient(data.data.client);
        } else {
           Toaster("error", "Error", res.data.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };












  //signature voice
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const [recordedAudio, setRecordedAudio] = useState(null);
  const [audioMimeType, setAudioMimeType] = useState(null);
  useEffect(() => {
    handleDelete();
  },[]);
  useEffect(() => {
    if(type){
    let timer;
    if (recorderControls.isRecording) {
      timer = setTimeout(() => {
        recorderControls.stopRecording();
      }, 7000); // Stop recording after 7 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }
  }, [recorderControls.isRecording]);

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setRecordedAudio(url);
    setAudioMimeType(blob.type);
  };

  const handleDelete = () => {
    setRecordedAudio(null);
    onSelectVoice(null)
  };

  useEffect(() => {
    // Perform an action when the "disabled" prop changes
    if (disable) {
      console.log("Disabled");
      // Add any other actions you want to perform when disabled
    } else {
      console.log("Enabled");
      // Add any other actions you want to perform when enabled
    }
  }, []);

  const handleSave = async () => {
    // Check if recordedAudio is a valid URL
    if (typeof recordedAudio === "string" && recordedAudio.trim() !== "") {
      try {
        const response = await fetch(recordedAudio);
        const blob = await response.blob();
  
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          // Now you have the Base64 string
          // if(type === 'pin'){
          //   const pin = inputRef.current?.input.value.trim();
          //   if(pin){
          //      onSelectVoice(base64String,pin,audioMimeType);
          //     setRecordedAudio(null);
          //      onSelectVoice(null)
          //   }
          //   else{
          //     Message.error('Please Enter Pin')
          //   }
          // }
          // else{
             onSelectVoice(base64String)
          // }
          // Now you can pass the base64String to your API
          // For example:
          // sendBase64ToAPI(base64String);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error fetching audio or converting to Base64:", error);
      }
    } else {
      console.error("recordedAudio is not a valid URL");
    }
  };

  const handleOnCancel = () =>{
    handleCancel();
    recorderControls.stopRecording();
  }
  const handleDone = () => {
    if (onDone) {
      const note = inputRef.current?.resizableTextArea?.textArea?.value.trim();
      console.log(note, "Notes");
      onDone(sigCanvas.current.toDataURL("image/png"), note);
      setIsSignatureEmpty(false); // Pass the signature data to the parent component
    }
  };
  const handleDraw = () => {
    setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
  };
  const isRecorded = (isStarted) => {
    // setDisabled(isStarted);
  };
  const onSelectVoice = (voice) => {
    if (voice) {
      let note = "";
      onDone(voice, note);
    } else {
      setTimeout(() => {
        // setDisabled((prev) => !prev);
      }, 0);

      // setDisabled(false)
      console.log(voice);
    }
  };

  return (

<>

    <Modal
    maskClosable={false}
    title={t("Digital Signature")}
    open={isDigitalOpen}
    // loading={warningLoading}
    // onOk={handleOk}
    onCancel={handleCancel}
    // footer={[
    //   <Button type="primary" className="ant-btn-modal" onClick={handleOk}>
    //      <FormattedMessage id="Save" />
    //   </Button>,
    // ]}
  >
    <Row
      gutter={[16, 0]}
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        fontWeight: "bold",
      }}
    >
       <Col span={18} xs={18} sm={18} md={18} lg={18} xl={18}>
          <div>
            <label>{t("PIN")}</label>
          </div>
          <Input
           ref={inputRef} // Attach the ref to the input element
            placeholder={t("Enter 4 digit PIN")}
            value={pin}
            maxLength={4}
            // onChange={handleChangePin}
            onChange={(e) =>
              handleChangePin(e.target.value, "pin")
            }
          />
        </Col>
    </Row>
  </Modal>

    <Modal
      title={type === "employee"  ? t("Employee Signature")  : type === "rp"   ? t("RP Signature")  : t("Client Signature")}
      open={showSignature}
      onCancel={handleOnCancel}
      closable={true}
      footer={null}
      maskClosable={false}
    >
      {/* <div>
        {isAddNotes && <TextArea autoSize placeholder="Enter Notes" ref={inputRef} />}
      </div> */}
      {/* <div>
                <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
                    {type === 'employee' ? 'Employee' : 'Client'} Signature
                </Title>
            </div> */}
      <div style={{ color: "red", marginTop:'5px' }}>
        <p>
        {t("PrivacyCertify")}
        </p>
      </div>
      <div>
        <div style={{ border: "1px dotted #ccc" }}>
            <SignatureCanvas
              canvasProps={{ width: 460, height: 250 }}
              ref={sigCanvas} onBegin={handleDraw}
            />
        </div>
        <div className="mt-25 text-right">
          <Button
          className="ant-btn-modal"
          type="primary"
          danger
            style={{ marginRight: "4px" }}
            onClick={clearCanvas}
            // disabled={disable}
          >
            {t("Clear")}
          </Button>
          <Button
          className="ant-btn-modal"
          style={{ marginRight: "4px" }}
            type="primary"
            loading={loading}
            onClick={handleDone}
            disabled={isSignatureEmpty || disable}
            // disabled={!sigCanvas.current || !sigCanvas.current.isEmpty()}
          >
            {t("Done")}
          </Button>
          {/* && type === 'employee' */}
          {(uType === "e" || uType === "c")  &&(
  <Button
  className="ant-btn-modal"
    type="primary"
    loading={loading}
    onClick={showDigitalModal}
    // disabled={isSignatureEmpty || disable}
  >
    {t("Digital Sign")}
  </Button>
          )}
        
        </div>
      </div>
      {type === "client" ? (
        // <div>
        //   <SignatureRecorder
        //     onVoice={onSelectVoice}
        //     isRecordStared={isRecorded}
        //     type="showTimer"
        //   />
        // </div>
        <div>
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
        showVisualizer={true}
      />
      <br />
      {recordedAudio && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <audio controls>
              <source src={recordedAudio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
            <Button
              danger
              onClick={handleDelete}
              style={{ marginRight: "6px" }}
            >
              {t("Delete")}
            </Button>
            <Button type="primary" onClick={handleSave}>
              {t("Save")}
            </Button>
          </div>
        </>
      )}
    </div>
      ) : null}
    </Modal>









    </>


  );
};

export default SignatureModal;
